import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { graphql } from "gatsby";

import SEO from "../components/globals/seo";
import Section from "../components/containers/section";
import Box from "../components/containers/box";
import Flex from "../components/containers/flex";
import Image from "../components/globals/image";
import Layout from "../components/containers/layout";
import Container from "../components/containers/container";
import Heading from "../components/typography/heading";
import Text from "../components/typography/text";
import TextWithLink from "../components/links/textWithLink";
import { Position, Absolute, Relative } from "../components/containers/position";
import MaxWidthAbsolute from "../components/containers/maxWidthAbsolute";
import AppStoreLinks from "../components/links/appstoreLinks";
import PageTitle from "../components/typography/pageTitle";
import Circle from "../components/globals/circle";

import Carousel from "../components/carousel/carousel";
import CarouselItem from "../components/carousel/carouselItem";
import CarouselQuote from "../components/carousel/carouselQuote";
import CarouselButton from "../components/carousel/carouselButton";
import Counter from "../components/carousel/counter";
import ProfileComponent from "../components/profile/profile";
import useCarousel from "../hooks/useCarousel";

const Profile = styled(ProfileComponent)`
  position: absolute;
  z-index: 5;

  @media (min-width: ${p => p.theme.screenM}) {
    top: 90%;
    left: 7%;
  }
  @media (min-width: 1400px) {
    top: 92%;
  }
`;

const ImageWrap = styled(Relative)`
  max-height: 625px;

  img {
    margin: 0 auto;
    max-height: 625px;
    border-radius: 10px;
  }
`;

const QuoteWrap = styled(Box)`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 130px;

  @media (min-width: ${p => p.theme.screenM}) {
    max-width: 420px;
    transform: translateX(130px);
    margin-top: 40px;
  }
  @media (min-width: ${p => p.theme.screenL}) {
    max-width: 620px;
  }
`;

const CoachPortalPage = ({ data, theme }) => {
  const { prismic } = data;
  const multiChildren = prismic.edges.length > 1;
  const { slideIndex, onDrag: onCarouselDrag, onNextClick: onCarouselNextClick } = useCarousel(prismic.edges.length);

  return (
    <Layout>
      <SEO
        title="Coach Portal"
        description="Find out how coaches can monitor and control their team's progress through the TopTekkers app."
      />

      <Section>
        <Container>
          <PageTitle>FOR COACHES</PageTitle>
        </Container>

        <Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          className="posRelative"
          maxWidth="1600px"
          mx="auto"
        >
          <Container width={[1, null]} order={[2, null, 1]}>
            <Heading as="h1" maxWidth={[null, null, 480, 580]}>
              Create teams to track the individual progress of your players
            </Heading>
          </Container>

          <Position
            width={[1, null, 1 / 2]}
            order={[1, null, 2]}
            mb={[20, null, 0]}
            pl={[20, 30, 0]}
            position={[null, null, `absolute`]}
            right={[null, null, -12, null, 0]}
            bottom={[`-26px`, null, `-40px`]}
          >
            <Image
              fluid={data.trackTeamStats.childImageSharp.fluid}
              alt="Profile page showing latest trophies and scores earned by the user"
              maxWidth={[null, null, 822]}
              borderRadius="10px 0 0 10px"
            />

            <Circle
              growMid
              bgColor={theme.yellow}
              top={[`60%`]}
              left={[-24, null, -20]}
              hideOnMobile
            />
            <Circle
              grow
              bgColor={theme.pink}
              top={[-24, null, -36, -72]}
              right={[`30%`]}
            />
          </Position>

          <Container
            width={[1, null]}
            order={[3, null, 1]}
            maxWidth={1200}
            alignSelf="flex-start"
          >
            <TextWithLink
              setWidth="370px"
              btnColor={theme.green}
              buttonText="Try it for Free!"
              href="https://app.toptekkers.club"
            >
              Securely connect with the players you coach via their parents
              email address, then add them to teams to organize, manage and
              track their progress and usage of TopTekkers.
            </TextWithLink>
          </Container>

          <Circle
            grow
            bgColor={theme.purple}
            top={[-70, null, -90, -170]}
            right={[-30, null, -46, -83]}
          />
          <Circle
            grow
            bgColor={theme.orange}
            top={`20%`}
            left={[-30, null, -120, null, -90]}
          />
        </Flex>
      </Section>

      <Section>
        <Container>
          <Heading as="h2" maxWidth={[null, null, 578]}>
            Give your players homework by assigning them Individual Development
            Plans
          </Heading>
          <Flex flexWrap="wrap">
            <Box
              width={[1, null, 1 / 2]}
              mb={[30, null, 0]}
              className="posRelative"
            >
              <Image
                fluid={data.buildATeam.childImageSharp.fluid}
                alt="List of players on a tema built by a coach on TopTekkers."
              />
              <Circle
                growMid
                bgColor={theme.red}
                bottom={[-30, null, -38]}
                right={[-30, null, -28]}
              />
              <Circle
                grow
                bgColor={theme.blue}
                top={[24, null, 36, 72]}
                left={[-30, null, -46, -83]}
              />
            </Box>

            <Box
              width={[1, null, 1 / 2]}
              max-Width={[null, null, null, 370]}
              pl={[0, null, 30, 40, 70]}
            >
              <TextWithLink
                btnColor={theme.green}
                buttonText="Create an account"
                href="https://app.toptekkers.club"
              >
                Every player is different, that’s why TopTekkers allows you to
                assign Individual Learning Plans to your players to encourage
                them to work on their skills away from the training ground.
                Players can view the techniques and challenges you have assigned
                them in their TopTekkers Player Portal.
              </TextWithLink>
            </Box>
          </Flex>
        </Container>
      </Section>
      
      {prismic.edges.length !== 0
      && (
      <Section>
        <Container>
          <Heading
            as="h2"
            textAlign="center"
            mx="auto"
            mb={[`40px`, null, null, `60px`, `80px`]}
            maxWidth="578px"
          >
            Used by the World’s best coaches
          </Heading>

          <Circle
            grow
            bgColor={theme.yellow}
            top="0"
            right={[-30, null, -46, -83]}
          />
        </Container>

        <Carousel
          slideIndex={slideIndex}
          onCarouselDrag={onCarouselDrag}
          multiChildren={multiChildren}
        >
          {prismic.edges.map((entry, i) => {
            const { data: entryData } = entry.node;
            const mainImage = entryData.main_image.localFile.childImageSharp.fluid;
            const profileData = { name: entryData.profile_name, title: entryData.profile_title, img: entryData.profile_image };

            return (

              // eslint-disable-next-line react/no-array-index-key
              <Container key={i}>
                <CarouselItem flexDirection="column">
                  <ImageWrap>
                    <Image fluid={mainImage} alt={entryData.main_image.alt} />

                    <Profile
                      maxWidth={[`100%`, null, `178px`]}
                      data={profileData}
                      mt={[`20px`, null, 0]}
                      alignItems={[`center`, null, `flex-start`]}
                      rowOnMob
                    />

                    {multiChildren && (
                      <>
                        <Absolute
                          top={[`0`, null, `unset`]}
                          bottom={[`0`, null, `10%`]}
                          right={[`0`, null, `-46px`]}
                          zIndex="5"
                        >
                          <CarouselButton onClick={onCarouselNextClick} />
                        </Absolute>

                        <Counter
                          current={i + 1}
                          max={prismic.edges.length}
                        />
                      </>
                    )}

                    <Circle
                      grow
                      bgColor={theme.pink}
                      bottom="45%"
                      left={[-30, null, -46, -83]}
                      hideOnMobile
                    />
                  </ImageWrap>

                  <QuoteWrap>
                    <CarouselQuote
                      quote={entryData.testimonial}
                      highlight={entryData.highlight}
                    />
                  </QuoteWrap>
                </CarouselItem>
              </Container>
            );
          })}
        </Carousel>
      </Section>
      )}

      <Section>
        <Container>
          <Flex flexWrap="wrap" alignItems="center">
            <Box
              width={[1, null, 1 / 2]}
              order={[2, null, 1]}
              pr={[0, null, 30, 40, 70]}
            >
              <Heading as="h2" setWidth="518px">
                Coach on the go with the TopTekkers app
              </Heading>

              <Text>
                Need to quickly assign a new Individual Learning Plan to a
                player, add a new player to your team or view who on your team
                has the most trophies? It’s all available at the click of a
                button right within the TopTekkers app.
              </Text>

              <AppStoreLinks />
            </Box>

            <Relative
              width={[1, null, 1 / 2]}
              order={[1, null, 2]}
              mb={[20, null, 0]}
              className="posRelative"
            >
              <Image
                fluid={data.coachOnTheGo.childImageSharp.fluid}
                alt="Mobile phone illustrations showing how parents can track the childs progress whilst on the go using the TopTekkers app."
              />

              <Circle
                growMid
                bgColor={theme.pink}
                bottom={`30%`}
                right={[-30, null, -42]}
              />
              <Circle
                grow
                bgColor={theme.yellow}
                top={[-6, null, -36, -60]}
                left={[`46%`, null, `38%`]}
              />
            </Relative>
          </Flex>
        </Container>
      </Section>

      <Section maxWidth="none" pb="0">
        <MaxWidthAbsolute>
          <Circle
            grow
            bgColor={theme.yellow}
            top={[-28, null, -46, -76]}
            left={[24, null, 36, 72]}
          />
          <Circle
            growMid
            bgColor={theme.blue}
            bottom={`20%`}
            left={`14%`}
            hideOnMobile
          />
          <Circle
            grow
            bgColor={theme.red}
            top={`44%`}
            right={[-28, null, -46, -90]}
            hideOnMobile
          />
        </MaxWidthAbsolute>

        {/* Overlay */}
        <Absolute
          bg="rgba(255, 255, 255, 0.9)"
          borderTop="1px solid #EBEBEB"
          top="0"
          bottom="0"
          left="0"
          right="0"
        />

        {/* Content */}
        <Container pt={[60, null, null, 100]} pb={[60, null, 70]}>
          <Heading as="h3" maxWidth="785px" textAlign="center" mx="auto">
            Take your coaching to the next level with TopTekkers
          </Heading>

          <TextWithLink
            setCenter
            btnColor={theme.green}
            buttonText="Create an account"
            setWidth="579px"
            href="https://app.toptekkers.club"
          >
            Talent is only part of the equation. For players to get to the very
            top of the game, they have to develop a love of practice and they
            have to be relentless. TopTekkers will help your team love
            practicing in a fun and challenging way.
          </TextWithLink>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    trackTeamStats: file(relativePath: { eq: "images/track-team-stats.png" }) {
      ...imageFragmentMax
    }
    coachOnTheGo: file(relativePath: { eq: "images/coach-on-the-go.png" }) {
      ...imageFragmentMax
    }
    buildATeam: file(relativePath: { eq: "images/build-a-team.png" }) {
      ...imageFragmentMax
    }
    onTheGo: file(relativePath: { eq: "images/on-the-go.png" }) {
      ...imageFragmentMax
    }
    profilePic: file(relativePath: { eq: "images/placeholderProfilePic.png" }) {
      ...imageFragmentProfilePic
    }

    prismic: allPrismicTestimonial(filter: {data: {show_coach: { eq: true}}}) { 
      edges {
        node {
          data {
            show_coach
            main_image {
              alt
              localFile {
                ...imageFragmentMax
              }
            }
            highlight
            testimonial
            profile_image {
              alt
              localFile {
                ...imageFragmentProfilePic
              }
            }
            profile_name
            profile_title
          }
        }
      }
    }
  }
`;

CoachPortalPage.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(CoachPortalPage);
